<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-investment">
      <!-- Card stats -->
      
      <!-- <b-row v-if="visualizar==0">
        <b-col xl="3" md="6">
          <stats-card title="Inversiones Pendientes"
                      type="gradient-red"
                      sub-title="10"
                      icon="ni ni-active-40"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">70%</span>
              <span class="text-nowrap">De las inversiones totales</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Inversiones Activas"
                      type="gradient-orange"
                      sub-title="2,356"
                      icon="ni ni-chart-pie-35"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Sales"
                      type="gradient-green"
                      sub-title="924"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-danger mr-2">5.72%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Performance"
                      type="gradient-info"
                      sub-title="49,65%"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">54.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col  class=""><b-button variant=" bg-mostaza" v-on:click="visualizar=1">Nueva Inversión</b-button></b-col>
        <!-- <b-col  class="pb-2 "><b-button class="bg-mostaza" v-on:click="visualizar=1">Nueva Inversion</b-button></b-col> -->
        <b-col  class="pb-2"><b-button class="float-right" variant="danger" v-on:click="visualizar=0" v-if="visualizar!=0">Cancelar</b-button></b-col>
      </b-row>
      
      
    </base-header>
    <b-container fluid class="mt--7">
      <Investments v-if="this.visualizar === 0"/>
      <CreateInvestment v-if="this.visualizar === 1"/>
      <SelectInvestment v-if="this.visualizar === 2"/>
      <EditInvestment v-if="this.visualizar === 3"/>
    </b-container>
  </div>
</template>
<script>
  import Investments  from '../components/Investments/Investments'
  import SelectInvestment  from '../components/Investments/SelectInvestment'
  import CreateInvestment  from '../components/Investments/CreateInvestment'
  import EditInvestment  from '../components/Investments/EditInvestment'
  import EventBus from '@/bus'

  export default {
    name: 'investment',
    components:{
        Investments, EditInvestment, CreateInvestment, SelectInvestment
    },
    data (){
        return {
            visualizar: 0
        }
    },
    created() {
        EventBus.$on('add-ruler', (item) => {
            this.visualizar = item
        })
    }
}
</script>
<style>
.el-table.table-dark{
  background-color: #172b4d;
  color: #f8f9fe;
}

.el-table.table-dark th,
.el-table.table-dark tr{
  background-color: #172b4d;
}

.el-table.table-dark td,
.el-table.table-dark th.is-leaf{
  border-bottom: none;
}
</style>
