<template>
    <b-card no-body>
        <div class="row my-5 mx-3">
            <div class="col-md-4 order-md-2">
                <h4 class="d-flex justify-content-between align-items-center">
                    <span class="text-muted">Detalles Oportunidad</span>
                </h4>
                <ul class="list-group mb-3 sticky-top">
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia proyectada diaria</h4>
                            <small class="text-muted">Ganancia bruta al día</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(gananciaDiaria().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">ISR</h4>
                            <small class="text-muted">Reducciones ISR</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description==='PFE' & this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(isr(20).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description==='PME' & this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(isr(0).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description===undefined || this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Fecha termino</h4>
                        </div>
                        <span class="text-muted">{{this.extraerfechafin(this.oportunity.end_date)}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia bruta</h4>
                            <small class="text-muted">Ganancia sin impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(gananciaTotal().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia neta</h4>
                            <small class="text-muted">Ganancia con impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney((gananciaTotal()-this.isr(this.valisr)).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA causado</h4>
                            <small class="text-muted">IVA generado</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA retenido</h4>
                            <small class="text-muted">IVA retenidopara pagar{{this.oportunity.customer.customertypes.type_description}}</small>
                        </div>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description==='PFE' & this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">-{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description==='PME' & this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">$0.00</span>
                        <span class="text-muted" v-if="this.oportunity.customer.customertypes.type_description===undefined || this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</span> 
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <span>Total (MEX)</span>
                        <strong v-if="this.oportunity.term.value.length !=0 & this.oportunity.rate.value.length !=0">{{stringtoMoney(total().toFixed(2))}}</strong>
                        <strong v-if="this.oportunity.term.value.length ===0 || this.oportunity.rate.value.length ===0">$0.00</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-8 order-md-1">
                <form @submit.prevent="editoportunitys()">
                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label>Capital inicial </label>
                            <input type="number" class="form-control" min="50000" step="0.01" v-model="this.oportunity.initial_amount" required="" disabled>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Inversion </label>
                            <br>
                            <input type="number" class="form-control" v-model="this.oportunity.investment.idinvestment" required="" disabled>
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                        <label>Capital inicial</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" class="form-control" min="50000" step="0.01" v-model="this.oportunity.initial_amount" required="" disabled>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label class="mr-2">Cliente : </label>
                            <br>
                            <label v-if="this.oportunity.customer.customertypes.type_description==='PFE'"> {{this.oportunity.customer.name + " " + this.oportunity.customer.last_name + " " +this.oportunity.customer.m_last_name }}</label>
                            <label v-if="this.oportunity.customer.customertypes.type_description==='PME'"> {{this.oportunity.customer.business_name}}</label>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Fecha Inicio (estimado) </label>
                            <br>
                            <input type="date" :value="this.extraerfecha(this.oportunity.start_date)" style="width: 100%; height:50%;" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Tasa :</label>
                            <input type="text" :value="this.oportunity.rate.text" disabled>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo :</label>
                            <input type="text" :value="this.oportunity.term.text" disabled>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo de pago :</label>
                            <input type="text" :value="this.oportunity.paymentTerms.text" disabled>
                        </div>
                    </div>
                    <hr class="mb-4">
                    <button v-if="this.oportunity.status.status != 'ACTIVO' && this.oportunity.status.status != 'LIQUIDADO'" class="btn btn-info btn-lg btn-block">Editar Oportunidad</button>
                </form>
            </div>
        </div>
    </b-card>
</template>

<script>
import EventBus from '@/bus'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'Selectoportunity',
    data () {
        return {
            visualizar: 0, 
            valisr: 0
        }
    },
    created() {
        // console.log(this.oportunity);
    },
    computed:{
        ...mapState(['token'])
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return '$' + x1 + x2 
        },
        getoportunitys() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        editoportunitys() {
            this.visualizar = 3
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        extraerfecha(date){
            let fecha = new Date(date)
            let a = fecha.getFullYear()
            let d = '' + fecha.getDate()
            let m = '' + (fecha.getMonth() + 1)
            if( m.length < 2 ){
                m = '0' + m
            }
            if( d.length < 2 ){
                d = '0' + d
            }
            return a + '-' + m + '-' + d
        },
        extraerfechafin(date){
            let fecha = new Date(date)
            let a = fecha.getFullYear()
            let d = '' + fecha.getDate()
            let m = '' + (fecha.getMonth() + 1)
            if( m.length < 2 ){
                m = '0' + m
            }
            if( d.length < 2 ){
                d = '0' + d
            }
            return d + '/' + m + '/' + a
        },
        gananciaDiaria(){
            return this.gananciaTotal()/this.oportunity.term.value
        },
        gananciaTotal(){
            return (this.oportunity.term.value/360)*this.oportunity.rate.value*this.oportunity.initial_amount/100
        },
        isr(val){
            return (parseInt(val)*this.gananciaTotal())/100
        },
        iva(){
            return (this.gananciaTotal()*16)/100
        },
        total(){
            switch (this.oportunity.customer.customertypes.type_description) {
                case "PFE":
                    this.valisr = 20;
                    return parseFloat(this.oportunity.initial_amount) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
                case "PME":
                    this.valisr = 0;
                    return parseFloat(this.oportunity.initial_amount) + this.gananciaTotal() - this.isr(this.valisr) +this.iva()
                    break;
                default:
                    this.valisr = 0
                    return parseFloat(this.oportunity.initial_amount) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
            }
        },
    },
    computed:{
        ...mapState(['oportunity']),
    }
}
</script>