<template>
    <b-card no-body>
            <!-- <b-card-header class="border-0">
                <h1 class="mb-0">Clientes</h1>
                <button class="btn btn-danger float-right mr-5" v-on:click="getCustomers">Cancelar</button>
            </b-card-header> -->

            <div v-if="editcustomer.tipo ==='PM'">
                    <b-card-body class="cardcustomer">
                        <b-form @submit.prevent="editInvestment()">
                            <!-- <div class="row">
                                <div class="col">
                                    <button class="btn btn-warning float-left mr-5" >Editar</button>
                                </div>
                                <div class="col">
                                    <button class="btn btn-danger float-right" v-on:click="getCustomers">Cancelar</button>
                                </div>
                            </div> -->
                            
                            <br>
                            <b-form-radio-group class="float-left" v-model="editcustomer.tipo">
                                <b-form-radio value="PM">Persona Moral</b-form-radio>
                            </b-form-radio-group><br><br>

                            <div class="row mt-2">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Razon Social" v-model="editcustomer.razon_social" >
                                </div>
                                <div class="col">
                                </div>
                                <div class="col">
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col">
                                    <label>Dirección: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Calle*" v-model="editcustomer.calle">
                                </div>
                                <div class="col">
                                    <label>Telefono 1: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Telefono" v-model="editcustomer.tel">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input v-on:keyup="buscador(editcustomer.cp)" type="text" class="form-control" placeholder="CP*" v-model="editcustomer.cp">
                                        </div>
                                        <div class="col">
                                            <b-form-select v-model="editcustomer.colonia" :options="this.options"></b-form-select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="CP*" v-model="editcustomer.cp">
                                        </div>
                                        <div class="col">
                                            <select id="inputState" class="form-control" v-model="editcustomer.colonia">
                                                <option selected>Colonia...</option>
                                                <option value="string">Mexico</option>
                                                <option value="Benito Juarez">Benito Juarez</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col">
                                    <label>Telefono 2: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Telefono" v-model="editcustomer.tel2">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="Ciudad*" v-model="editcustomer.ciudad">
                                        </div>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="Entidad*" v-model="editcustomer.entidad">
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <label>Sitio Web: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Sitio Web" v-model="editcustomer.website">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Pais*" v-model="editcustomer.pais">
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label>RFC: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="RFC*" v-model="editcustomer.rfc">
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                            </div>
                        </b-form>
                        <button class="btn btn-warning mt-6" >Editar</button>
                    </b-card-body>
                </div>


                <div v-if="editcustomer.tipo ==='PF'">
                    <b-card-body class="cardcustomer">
                        <b-form @submit.prevent="editInvestment()">
                            <!-- <div class="row">
                                <div class="col">
                                    <button class="btn btn-warning float-left mr-5" >Editar</button>
                                </div>
                                <div class="col">
                                    <button class="btn btn-danger float-right" v-on:click="getCustomers">Cancelar</button>
                                </div>
                            </div>
                             -->
                            <br>
                            <b-form-radio-group class="float-left" v-model="editcustomer.tipo">
                                <b-form-radio value="PF">Persona Fisica</b-form-radio>
                            </b-form-radio-group><br><br>

                            <div class="row mt-2">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Nombre" v-model="editcustomer.nombre">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Apellido Paterno" v-model="editcustomer.ape_pat">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Apellido Materno" v-model="editcustomer.ape_mat">
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col">
                                    <label>Dirección: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Calle*" v-model="editcustomer.calle">
                                </div>
                                <div class="col">
                                    <label>Telefono: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Telefono" v-model="editcustomer.tel">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input v-on:keyup="buscador(customer.cp)" type="text" class="form-control" placeholder="CP*" v-model="customer.cp">
                                        </div>
                                        <div class="col">
                                            <b-form-select v-model="customer.colonia" :options="this.options"></b-form-select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="CP*" v-model="editcustomer.cp">
                                        </div>
                                        <div class="col">
                                            <select id="inputState" class="form-control" v-model="editcustomer.colonia">
                                                <option selected>Colonia...</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Benito Juarez">Benito Juarez</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col">
                                    <label>Movil: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Movil" v-model="editcustomer.tel2">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="Ciudad*" v-model="editcustomer.ciudad">
                                        </div>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="Entidad*" v-model="editcustomer.entidad">
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <label>Correo Electronico: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Correo Electronico" v-model="editcustomer.email">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Pais*" v-model="editcustomer.pais">
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <label>RFC: </label>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="RFC*" v-model="editcustomer.rfc">
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                                <div class="col">
                                    <label></label>
                                </div>
                            </div>
                        </b-form>
                        <button class="btn btn-warning mt-6" >Editar</button>
                    </b-card-body>
                </div>
    </b-card>
</template>

<script>
import EventBus from '@/bus'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'EditCustomer',
    data () {
        return {
            cps:[],cpsfiltrados:[],options:[],
            visualizar: 0,
            mensaje: {color: 'success', texto: ''},
            editcustomer: [],
        }
    },
    created() {
        this.editcustomer = this.customer 
        this.listCp()
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        listCp(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/cp', config)
            .then(res => {
                this.cps = res.data
            })
            .catch(e => {
            })
        },
        buscador(cp){
            this.cpsfiltrados = [{dasenta: '',dciudad: '',dcodigo: '',destado: '',idCodigo: ''}]
            this.options = []
            for(let codigo of this.cps){
                let valor = codigo.dcodigo
                if(valor.toString().toLowerCase()=== cp){
                    this.cpsfiltrados.push(codigo)
                    this.customer.ciudad = codigo.dciudad
                    this.customer.entidad = codigo.destado
                    this.customer.pais = "Mexico"
                    this.options.push({value:codigo.dasenta, text:codigo.dasenta})
                }
            }
            this.cpsfiltrados.splice(0,1)
        },
        editInvestment(){
            switch (this.editcustomer.tipo) {
                case "PF":
                    this.editcustomer.razon_social = null, this.editcustomer.website = null
                    break;
                case "PM":
                    this.editcustomer.nombre = null, this.editcustomer.ape_mat = null, this.editcustomer.ape_pat = null, this.editcustomer.email = null
                    break;
            }
            let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
            this.axios.put('/customer', this.editcustomer, config)
                .then(res => {
                    this.mensaje.color = 'success'
                    this.mensaje.texto = 'Se ha actualizado el Cliente'
                    this.alerta(this.mensaje)
                    this.getCustomers()
            })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
            })
        },
        getCustomers() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        }
    },
    computed:{
        ...mapState(['customer', 'token']),
    }
}
</script>