<template>
    <b-card no-body>
        <b-card-body class=" mb-2" v-if="selectcustomer.customertypes.type_description ==='PF'">
                <b-form>
                    <b-form-radio-group class="float-left" v-model="selectcustomer.customertypes.type_description">
                        <b-form-radio value="PF">Persona Fisica</b-form-radio>
                    </b-form-radio-group><br><br>

                    <div class="row mt-2">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Nombre" v-model="customer.name" disabled>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Apellido Paterno" v-model="customer.last_name" disabled>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Apellido Materno" v-model="customer.m_last_name" disabled>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col">
                            <label>Dirección: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Calle*" v-model="customer.street" disabled>
                        </div>
                        <div class="col">
                            <label>Telefono: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="CP*" v-model="customer.cp.dcodigo" disabled>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="CP*" v-model="customer.cp.dasenta" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Movil: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Movil" v-model="customer.tel2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Ciudad*" v-model="customer.cp.dciudad" disabled>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Entidad*" v-model="customer.cp.destado" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Correo Electronico: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Correo Electronico" v-model="customer.email">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Mexico" disabled>
                        </div>
                        <div class="col">
                            <label>Numero Exterior: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero exterior" v-model="customer.no_ext" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label>RFC: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="RFC*" v-model="customer.rfc" disabled>
                        </div>
                        <div class="col">
                            <label>Numerio Interior</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero interior" v-model="customer.no_int" disabled>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <label>Comisionista: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Comisionista" v-model="customer.commissionist.name " disabled>
                        </div>
                        <div class="col">
                            <label>Nacionalidad</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Comisionista" v-model="customer.nationalities.nationality " disabled>
                        </div>
                    </div>
                    
                    <div class="row mt-6">
                        <!-- <div class="col">
                            <button class="btn btn-success float-left mr-5">Agregar</button>
                        </div> -->
                    </div>
                </b-form>
            </b-card-body>


        <b-card-body class=" mb-2" v-if="selectcustomer.customertypes.type_description ==='PM'">
                <b-form @submit.prevent="createCustomer(customer)">
                    <b-form-radio-group class="float-left" v-model="selectcustomer.customertypes.type_description">
                        <b-form-radio value="PM">Persona Moral</b-form-radio>
                    </b-form-radio-group><br><br>

                    <div class="row mt-2">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Razon Social" v-model="customer.business_name" disabled>
                        </div>
                        <div class="col">
                        </div>
                        <div class="col">
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col">
                            <label>Dirección: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Calle*" v-model="customer.street" disabled>
                        </div>
                        <div class="col">
                            <label>Telefono 1: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="CP*" v-model="customer.cp.dcodigo" disabled>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="CP*" v-model="customer.cp.dasenta" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Telefono 2: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel2" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Ciudad*" v-model="customer.cp.dciudad" disabled>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Entidad*" v-model="customer.cp.destado" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Sitio Web: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Sitio Web" v-model="customer.website" disabled>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Mexico" disabled>
                        </div>
                        <div class="col">
                            <label>Numero Exterior: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero exterior" v-model="customer.no_ext" disabled>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label>RFC: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="RFC*" v-model="customer.rfc" disabled>
                        </div>
                        <div class="col">
                            <label>Numerio Interior</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero interior" v-model="customer.no_int" disabled>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <label>Comisionista: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Comisionista" v-model="customer.commissionist.name " disabled>
                        </div>
                        <div class="col">
                            <label>Nacionalidad</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Comisionista" v-model="customer.nationalities.nationality " disabled>
                        </div>
                    </div>

                    <div class="row mt-6">
                        <!-- <div class="col">
                            <button class="btn btn-success float-left mr-5" >Agregar</button>
                        </div> -->
                    </div>
                </b-form>
            </b-card-body>
            <!-- Fin de Tarjeta Persona Moral -->
    </b-card>
</template>

<script>
import EventBus from '@/bus'
import {mapState} from 'vuex'

export default {
    name: 'SelectCustomer',
    data () {
        return {
            visualizar: 0,
            selectcustomer: []
        }
    },
    created() {
        this.selectcustomer = this.customer 
        // console.log(this.selectcustomer);
    },
    methods: {
        getCustomers() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        editCustomers() {
            this.visualizar = 3
            EventBus.$emit('add-ruler', this.visualizar)            
        },
    },
    computed:{
        ...mapState(['customer']),
    }
}
</script>