<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 navbar-bg">
      <!-- Card stats -->
      
      <b-row>
        <b-col xl="3" md="6">
          <stats-card title="Inversiones Pendientes"
                      type="gradient-red"
                      sub-title="35"
                      icon="ni ni-active-40"
                      class="mb-4">

            <template slot="footer">
              <span class="text-primary mr-2">70%</span>
              <span class="text-nowrap">De las inversiones totales</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Inversiones Activas"
                      type="gradient-orange"
                      sub-title="2"
                      icon="ni ni-chart-pie-35"
                      class="mb-4">

            <template slot="footer">
              <span class="text-primary mr-2">12.18%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Cantidad de Inversiones"
                      type="gradient-green"
                      sub-title="37"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-danger mr-2">5.72%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="6">
          <stats-card title="Crecimiento de Inversiones"
                      type="gradient-info"
                      sub-title="12,65%"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">54.8%</span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col  class=""><b-button variant=" bg-mostaza" v-on:click="visualizar=1">Nuevo Inversionista</b-button></b-col>
        <!-- <b-col  class="pb-2 "><b-button  v-on:click="visualizar=1" class="bg-mostaza">Nuevo Inversionista</b-button></b-col> -->
        <b-col  class="pb-2"><b-button class="float-right" variant="danger" v-on:click="visualizar=0" v-if="visualizar!=0">Cancelar</b-button></b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--7">
      <Customers v-if="this.visualizar === 0"/>
      <CreateCustomer v-if="this.visualizar === 1"/>
      <SelectCustomer v-if="this.visualizar === 2"/>
      <EditCustomer v-if="this.visualizar === 3"/>
    </b-container>
  </div>
</template>
<script>
  import Customers  from '../components/Customers/Customers'
  import SelectCustomer  from '../components/Customers/SelectCustomer'
  import CreateCustomer  from '../components/Customers/CreateCustomer'
  import EditCustomer  from '../components/Customers/EditCustomer'
  import EventBus from '@/bus'

  export default {
    name: 'Customer',
    components:{
        Customers, EditCustomer, CreateCustomer, SelectCustomer
    },
    data (){
        return {
            visualizar: 0
        }
    },
    created() {
        EventBus.$on('add-ruler', (item) => {
            this.visualizar = item
        })
    }
}
</script>
<style>
.el-table.table-dark{
  background-color: #172b4d;
  color: #f8f9fe;
}

.el-table.table-dark th,
.el-table.table-dark tr{
  background-color: #172b4d;
}

.el-table.table-dark td,
.el-table.table-dark th.is-leaf{
  border-bottom: none;
}

</style>
