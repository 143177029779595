<template>

  <b-card class="table-main table-shadow" body-class="p-0" header-class="border-0 table-header-bg">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 table-title">Top clientes</h3>
        </b-col>
      </b-row>
    </template>

    <el-table class="table-responsive table table-striped"
              :data="tableData"
              header-row-class-name="thead-light">
      <el-table-column label="Nombre"
                       min-width="130px"
                       prop="name">
        <template v-slot="{row}">
          <div class="font-weight-600">{{row.name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="Tipo"
                       min-width="70px"
                       prop="type">
      </el-table-column>
      <el-table-column label="Monto Total"
                       min-width="90px"
                       prop="amount">
      </el-table-column>
    </el-table>
  </b-card>
</template>
<script>
  import {mapState, mapActions} from 'vuex'
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'top-clients-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        topClients: [],
        tableData: [
        ]
      }
    },
    methods:{
      ...mapActions(['cerrarSesion']),
      listtopClients(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/topclients', config)
            .then(res => {
                this.topClients = res.data
                res.data.forEach(element => {
                  const stringtoMoney = (item)=> {
                    item += ''
                    var x = item.split('.')
                    var x1 = x[0]
                    var x2 = x.length > 1 ? '.' + x[1] : ''
                    var rgx = /(\d+)(\d{3})/
                    while (rgx.test(x1)) {
                        x1 = x1.replace(rgx, '$1' + ',' + '$2')
                    }
                    return '$' + x1 + x2 
                  }
                  if(element[6]===1){
                    this.topClients={type: 'Persona Fisica',name : element[3] + " " +element[4] + " " + element[5], amount : stringtoMoney(element[0].toFixed(2))}
                    this.tableData.push(this.topClients)
                  }else{
                    this.topClients={type: 'Persona Moral',name : element[2] , amount : stringtoMoney(element[0].toFixed(2))}
                    this.tableData.push(this.topClients)
                  }
                });
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })
        },
    },
    computed: {
        ...mapState(['token'])
    },
    created(){
      this.listtopClients()
    }
  }
</script>
<style>
</style>
