<template>
    <b-card no-body>
        <div class="row my-5 mx-3">
            <div class="col-md-4 order-md-2">
                <h4 class="d-flex justify-content-between align-items-center">
                    <span class="text-muted">Detalles Inversión</span>
                </h4>
                <ul class="list-group mb-3 sticky-top">
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia proyectada diaria</h4>
                            <small class="text-muted">Ganancia bruta al día</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaDiaria().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">ISR</h4>
                            <small class="text-muted">Reducciones ISR</small>
                        </div>
                        <span class="text-muted" v-if="this.customer.tipo==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(20).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(0).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Fecha termino</h4>
                        </div>
                        <span class="text-muted">{{fechaFinMuestra()}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia bruta</h4>
                            <small class="text-muted">Ganancia sin impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaTotal().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia neta</h4>
                            <small class="text-muted">Ganancia con impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney((gananciaTotal()-this.isr(this.valisr)).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA causado</h4>
                            <small class="text-muted">IVA generado</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA retenido</h4>
                            <small class="text-muted">IVA retenido para pagar</small>
                        </div>
                        <span class="text-muted" v-if="this.customer.tipo==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">-{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.customer.tipo==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">$0.00</span>
                        <span class="text-muted" v-if="this.customer.tipo===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span> 
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <span>Total (MEX)</span>
                        <strong v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(total().toFixed(2))}}</strong>
                        <strong v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-8 order-md-1">
                <form @submit.prevent="createInvestment()">
                    <div class="mb-3">
                        <label>Capital inicial</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" class="form-control" min="50000" step="0.01" v-model="monto" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label>Cliente </label>
                            <b-form-select v-model="customer" :options="customers" placeholder="" value="" required=""></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Fecha Inicio (estimado) </label>
                            <br>
                            <input type="date" v-model="fechainicio" style="width: 100%; height:50%;">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Tasa:</label>
                            <b-form-select v-model="tasavalor" :options="tasa"></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo:</label>
                            <b-form-select v-model="plazovalor" :options="plazo"></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo de pago:</label>
                            <b-form-select v-model="plazopagovalor" :options="plazopago"></b-form-select>
                        </div>
                    </div>
                    <hr class="mb-4">
                    <button class="btn btn-primary btn-lg btn-block">Agregar Inversión</button>
                </form>
            </div>
        </div>
    </b-card>
</template>
<script>
import EventBus from '@/bus'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'CreateInvestment',
    data () {
        return {
            customers:[],perro: "lala",
            visualizar: 0,
            fechainicio : null,
            plazo: [],
            tasa: [],
            plazopago: [
                // { value: 0, text: 'Selecciona una opcion'},
                // { value: 30, text: '30 dias' },
                // { value: 40, text: '40 dias' },
                // { value: 90, text: '90 dias' }
            ],
            plazovalor: [],
            tasavalor: [],
            plazopagovalor: [],
            customer: [],
            monto: 100000,
            reinversion: true,
            mensaje: {color: 'success', texto: ''},
            valisr:0
        }
    },
    filters: {
        capitalize: function (value) {
            return value.toUpperCase()
        }
    },
    created(){
        this.listCustomers(),
        this.listPlazos(),
        this.listTasa(),
        this.listPlazoPago()
    },
    computed:{
        ...mapState(['token'])
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return '$' + x1 + x2
        },
        listPlazos(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catterms/TI', config)
            .then(res => {
                // this.plazo=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazo=json
            })
            .catch(e => {
            })
        },
        listPlazoPago(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catpaymentsterms/PI', config)
            .then(res => {
                // this.plazopago=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazopago=json
            })
            .catch(e => {
            })
        },
        listTasa(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catrate/TI', config)
            .then(res => {
                // this.tasa=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.tasa=json
            })
            .catch(e => {
            })
        },
        createInvestment(){
            // console.log(this.customer.tipo);
            // console.log(this.fechaFin() + 'T00:00:00');
            // console.log(this.fechainicio + 'T00:00:00');
            let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
            let investment = {
                // "customername": this.customer.nombre,
                "end_date": Date.parse(this.fechaFin() + 'T00:00:00'),
                "customer": this.customer,
                "initial_amount": this.monto,
                "paymentTerms": this.plazopagovalor,
                "rate": this.tasavalor,
                "term": this.plazovalor,
                // balance es el saldo que va sumando al final debe ser igual al monto final en este registro
                "balance": this.monto,
                "payments_made": 0,
                "start_date": Date.parse(this.fechainicio + 'T00:00:00'),
                "status": { id_status : 2 },
                "total_amount_capitalized": this.total()
            }
            this.axios.post('/investments', investment, config)
                .then(res => {
                    this.mensaje.color = 'success'
                    this.mensaje.texto = 'Se ha agregado la Inversion'
                    this.alerta(this.mensaje)
                    this.getInvestments()
            })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
            })
        },
        getInvestments() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        fechaFin() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return a + '-' + m + '-' + d
            }
        },
        fechaFinMuestra() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return d + '/' + m + '/' + a
            }
        },
        gananciaDiaria(){
            switch (this.plazovalor.value) {
                case 0:
                    return 0
                    break;
                default:
                    return this.gananciaTotal()/this.plazovalor.value
                    break;
            }
        },
        gananciaTotal(){
            return (this.plazovalor.value/360)*this.tasavalor.value*this.monto/100
        },
        isr(val){
            return (parseInt(val)*this.gananciaTotal())/100
        },
        iva(){
            return (this.gananciaTotal()*16)/100
        },
        total(){
            switch (this.customer.tipo) {
                case "PF":
                    // console.log("PF");
                    this.valisr = 20;
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.monto) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
                case "PM":
                    // console.log("PM");
                    this.valisr = 0;
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.monto) + this.gananciaTotal() - this.isr(this.valisr) +this.iva()
                    break;
                default:
                    // console.log("DEFAULT");
                    this.valisr = 0
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.monto) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
            }
            
        },
        listCustomers(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catcustomers', config)
            .then(res => {
                let a = null
                for(let customer of res.data){
                    if(customer.customertypes.type_description === 'PM'){
                        this.customers.push({value:{ id_customer: customer.id_customer, nombre: customer.business_name, tipo:'PM' }, text: customer.business_name})
                    }else{
                        a = customer.name + ' ' + customer.last_name + ' ' + customer.m_last_name
                        this.customers.push({value:{ id_customer: customer.id_customer, nombre: a, tipo:'PF' }, text: a})
                    }  
                }
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })
        },
    }
}


</script>