<template>
    <b-card no-body>
            <!-- <b-card-header class="border-0">
                <h1 class="mb-0">Clientes</h1>
            </b-card-header> -->

            <!-- Tarjeta para Persona Fisica -->
            <b-card-body class=" mb-2" v-if="customer.tipo==='PF'">
                <b-form  @submit.prevent="createCustomer(customer)">
                    <b-form-radio-group class="float-left" v-model="customer.tipo">
                        <b-form-radio value="PF">Persona Fisica</b-form-radio>
                        <b-form-radio value="PM">Persona Moral</b-form-radio>
                    </b-form-radio-group><br><br>

                    <div class="row mt-2">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Nombre" v-model="customer.name">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Apellido Paterno" v-model="customer.last_name">
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Apellido Materno" v-model="customer.m_last_name">
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col">
                            <label>Dirección: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Calle*" v-model="customer.street">
                        </div>
                        <div class="col">
                            <label>Telefono: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input v-on:keyup="buscador(valor)" type="text" class="form-control" placeholder="CP*" v-model="valor">
                                </div>
                                <div class="col">
                                    <b-form-select v-model="customer.cp" :options="this.options"></b-form-select>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Movil: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Movil" v-model="customer.tel2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Ciudad*" v-model="customer.ciudad" disabled="true">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Entidad*" v-model="customer.entidad" disabled="true">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>CURP: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="CURP" v-model="customer.curp">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Pais*" v-model="customer.pais" disabled="true">
                        </div>
                        <div class="col">
                            <label>Numero Exterior: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero exterior" v-model="customer.no_ext">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label>RFC: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="RFC*" v-model="customer.rfc">
                        </div>
                        <div class="col">
                            <label>Numerio Interior</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero interior" v-model="customer.no_int">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <label>Comisionista: </label>
                        </div>
                        <div class="col">
                            <b-form-select v-model="customer.commissionist" :options="this.commissionist"></b-form-select>
                        </div>
                        <div class="col">
                            <label>Nacionalidad</label>
                        </div>
                        <div class="col">
                            <b-form-select v-model="customer.nationalities" :options="this.nationalities"></b-form-select>
                        </div>
                    </div>
                    
                    <div class="row mt-6">
                        <div class="col">
                            <button class="btn btn-success float-left mr-5">Agregar</button>
                        </div>
                    </div>
                </b-form>
            </b-card-body>
            <!-- Fin de tarjeta Persona Fisica -->

            <!-- Inicio de tarjeta Persona Moral -->
            <b-card-body class=" mb-2" v-if="customer.tipo==='PM'">
                <b-form @submit.prevent="createCustomer(customer)">
                    <b-form-radio-group class="float-left" v-model="customer.tipo">
                        <b-form-radio value="PF">Persona Fisica</b-form-radio>
                        <b-form-radio value="PM">Persona Moral</b-form-radio>
                    </b-form-radio-group><br><br>

                    <div class="row mt-2">
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Razon Social" v-model="customer.business_name">
                        </div>
                        <div class="col">
                        </div>
                        <div class="col">
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col">
                            <label>Dirección: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Calle*" v-model="customer.street">
                        </div>
                        <div class="col">
                            <label>Telefono 1: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input v-on:keyup="buscador(valor)" type="text" class="form-control" placeholder="CP*" v-model="valor">
                                </div>
                                <div class="col">
                                    <b-form-select v-model="customer.cp" :options="this.options"></b-form-select>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Telefono 2: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Telefono" v-model="customer.tel2">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Ciudad*" v-model="customer.ciudad" disabled="true">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Entidad*" v-model="customer.entidad" disabled="true">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Sitio Web: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Sitio Web" v-model="customer.website">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Pais*" v-model="customer.pais" disabled="true">
                        </div>
                        <div class="col">
                            <label>Numero Exterior: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero exterior" v-model="customer.no_ext">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <label>RFC: </label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="RFC*" v-model="customer.rfc">
                        </div>
                        <div class="col">
                            <label>Numerio Interior</label>
                        </div>
                        <div class="col">
                            <input type="text" class="form-control" placeholder="Numero interior" v-model="customer.no_int">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <label>Comisionista: </label>
                        </div>
                        <div class="col">
                            <b-form-select v-model="customer.commissionist" :options="this.commissionist"></b-form-select>
                        </div>
                        <div class="col">
                            <label>Nacionalidad</label>
                        </div>
                        <div class="col">
                            <b-form-select v-model="customer.nationalities" :options="this.nationalities"></b-form-select>
                        </div>
                    </div>

                    <div class="row mt-6">
                        <div class="col">
                            <button class="btn btn-success float-left mr-5" >Agregar</button>
                        </div>
                    </div>
                </b-form>
            </b-card-body>
            <!-- Fin de Tarjeta Persona Moral -->
    </b-card>
</template>

<script>
import EventBus from '@/bus'
import {mapState, mapActions, mapGetters} from 'vuex'

export default {
    name: 'CreateCustomer',
    data () {
        return {
            valor:"",commissionist:[],nationalities:[],
            cps:[],cpsfiltrados:[],options:[],
            visualizar: 0,
            mensaje: {color: 'success', texto: ''},
            customer:{
                ape_mat: '', ape_pat: '', calle: '', ciudad: '', colonia: '', cp: '', email: '', entidad: '',
                nombre: '', pais: '', razon_social: '', rfc: '', tel: '', tel2: '', tipo: 'PF', website: ''
            }
        }
    },
    created(){
        this.listCp()
        this.listCommissionists()
        this.listNationalities()
    },
    computed:{
        ...mapState(['token']),
        ...mapGetters(['filteredCP']),
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        listNationalities(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/nationalities', config)
            .then(res => {
                // console.log(res.data);
                for(let nacionalidad of res.data){
                    this.nationalities.push({value:nacionalidad, text:nacionalidad.nationality})
                }
                // this.commissionist = res.data
            })
            .catch(e => {
            })
        },
        listCommissionists(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/commissionist', config)
            .then(res => {
                // console.log(res.data);
                for(let comisionista of res.data){
                    let valor = comisionista.name + " " + comisionista.last_name + " " + comisionista.m_last_name
                    // console.log(valor);
                    this.commissionist.push({value:comisionista, text:valor})
                }
                // this.commissionist = res.data
            })
            .catch(e => {
            })
        },
        listCp(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/cp', config)
            .then(res => {
                this.cps = res.data
            })
            .catch(e => {
            })
        },
        buscador(cp){
            this.cpsfiltrados = [{dasenta: '',dciudad: '',dcodigo: '',destado: '',idCodigo: ''}]
            this.options = []
            for(let codigo of this.cps){
                let valor = codigo.dcodigo
                if(valor.toString().toLowerCase()=== cp){
                    this.cpsfiltrados.push(codigo)
                    this.customer.ciudad = codigo.dciudad
                    this.customer.entidad = codigo.destado
                    this.customer.pais = "Mexico"
                    this.options.push({value:codigo, text:codigo.dasenta})
                }
            }
            this.cpsfiltrados.splice(0,1)
        },
        createCustomer(){
            switch (this.customer.tipo) {
                case "PF":
                    this.customer.business_name = null, this.customer.website = null
                    this.customer.customertypes = {"id_customer_types": 1}
                    break;
                case "PM":
                    this.customer.name = null, this.customer.m_last_name = null, this.customer.last_name = null, this.customer.email = null
                    this.customer.customertypes = {"id_customer_types": 2}
                    break;
            }
            let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
            this.axios.post('/customers', this.customer, config)
                .then(res => {
                    this.mensaje.color = 'success'
                    this.mensaje.texto = 'Se ha agregado al Cliente'
                    this.alerta(this.mensaje)
                    this.getCustomers()
            })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
            })
            // console.log(this.customer);
        },
        getCustomers() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
    }
}
</script>