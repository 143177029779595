<template>
  <div>
    <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center profile-header"
        style="min-height: 600px; background-image: url(img/theme/profile-cover.jpg); background-size: cover; background-position: center top;">
      <b-container fluid>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center">
          <b-row >
            <b-col lg="5">
              <div>
                <img class="profile-logo" src="../../assets/img/profile-logo.png">
              </div>
            </b-col>
            <b-col lg="7">
              <div class="profile-container">
                <h1 class="profile-tittle display-2 text-white">¡Bienvenido!</h1>
                <p class="profile-description text-white mt-0 mb-5">Hoy eres parte de esta gran empresa, súmate a nosotros y dejanos conocer más de tí.</p>
                <a href="#!" class="btn btn-main">Editar Perfil</a>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="4" class="order-xl-2 mb-5">
          <user-card></user-card>
        </b-col>
        <b-col xl="8" class="order-xl-1">
          <edit-profile-form></edit-profile-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import EditProfileForm from './UserProfile/EditProfileForm.vue';
  import UserCard from './UserProfile/UserCard.vue';

  export default {
    components: {
      EditProfileForm,
      UserCard
    }
  };
</script>
<style>
</style>
