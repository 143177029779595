<template>
  <div>
    <!-- Header -->
    <div class="header bg-dashboardtop py-7 py-lg-8 pt-lg-9">
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <!-- Table -->
      <b-row class="justify-content-center">
        <b-col lg="6" md="8" >
          <b-card no-body class="bg-secondary border-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h1>Registro de Usuario</h1>
              </div>
              <validation-observer ref="formValidator">
                <b-form role="form" @submit.prevent="agregarUser()">
                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Nombre"
                              name="Name"
                              :rules="{required: true}"
                              v-model="model.nombre">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Apellido Paterno"
                              name="Name"
                              :rules="{required: true}"
                              v-model="model.ape_pat">
                  </base-input>
                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Apellido Materno"
                              name="Name"
                              :rules="{required: true}"
                              v-model="model.ape_mat">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-email-83"
                              placeholder="Email"
                              name="Email"
                              :rules="{required: true, email: true}"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="Contraseña"
                              type="password"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              v-model="model.contraseña">
                  </base-input>
                  <div class="text-center">
                    <b-button type="submit" variant="primary" class="mt-4">Crear Usuario</b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div id="footer"></div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import EventBus from '@/bus'
import router from '@/routes/router'
  export default {
    name: 'register',
    data() {
      return {
        mensaje: {color: 'success', texto: ''},
        model: {
          ape_mat: '',
          ape_pat: '',
          contraseña: '',
          nombre: '',
          rol: 0,
          status: 1,
          username: '',
          agree: false
        }
      }
    },
    methods: {
      onSubmit() {
        // this will be called only after form is valid. You can do an api call here to register users
      },
      agregarUser(){
          this.axios.post('/insertuser', this.model)
          .then(res => {
              this.model.ape_mat = ''
              this.model.ape_pat = ''
              this.model.contraseña = ''
              this.model.nombre = ''
              this.model.username = ''
              this.mensaje.color = 'success',
              this.mensaje.texto = 'Se ha agregado al usuario',
              this.alerta(this.mensaje)
              router.push({name: 'login'})
          })
          .catch(e => {
              this.mensaje.color = 'danger',
              this.mensaje.texto = 'Existio un error al registrar',
              this.alerta(this.mensaje)
          })
      },
      alerta(item) {
          EventBus.$emit('alerta', item)            
      },
    }

  };
</script>

<style scoped>
.bg-dashboardtop {
  background-color: #F2F0DF;
  /* background: url("../../assets/login/fondoinferiorlogin.png"); */
}

#footer {
    background: url("../../assets/login/fondoinferiorlogin.png");
    line-height: 2;
    height: 750px;
    margin-top: -26.7%;
}
</style>
