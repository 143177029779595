<template>
    <b-card no-body class=" table-shadow table-container">
        <!-- <b-card-header class="border-0" @submit.prevent="buscador(texto)">
            <h1 class="mb-0"><strong>Inversiones</strong></h1>
            <input type="submit" value="Nuevo" class="btn btn-success float-right ml-5 " v-on:click="createInvestment" >
            <input type="text" placeholder="Buscar..." v-model="texto" v-on:keyup="buscador(texto)" class="float-right mt-2">
        </b-card-header> -->
        
        <!-- <input type="submit" value="Nuevo" class="btn btn-success float-right ml-5 " v-on:click="createInvestment" > -->
        <div class="overflow-auto table-secondary">

            <b-table
                hover
                striped
                id="my-table"
                :items="formartedItems"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                thead-class="table-header-bg text-white text-center"
                @row-clicked="myRowClickHandler"
                small
            >
            <template #cell(initial_amount)="data">
                <label class="float-right">{{stringtoMoney(data.item.initial_amount)}}</label>
            </template>
            <template #cell(initial_amount)="data">
                <label class="float-right">{{stringtoMoney(data.item.initial_amount)}}</label>
            </template>
            <template #cell(rate.text)="data">
                <label class="float-right">{{data.item.rate.text}}</label>
            </template>
            <template #cell(term.text)="data">
                <label class="center-column">{{data.item.term.text}}</label>
            </template>
            <template #cell(start_date)="data">
                <label class="float-right">{{new Date(data.item.start_date).getDate() + '/' + (new Date(data.item.start_date).getMonth()+1) + '/' + new Date(data.item.start_date).getFullYear() }}</label>
            </template>
            <!-- <template v-slot:[`data.item.status.status`]='{ data }'> -->
            <template #cell(status.status)="data">
            <!-- <template v-slot:[`item.status.status`]="{ data }"> -->
                <button v-if="data.item.status.status ==='PENDIENTE'" class="btn btn-success" v-on:click="activarCustomers(data.item)">ACTIVAR</button>
                <button v-if="data.item.status.status ==='PENDIENTE'" class="btn btn-danger ml-2" v-on:click="removeCustomers(data.item)">ELIMINAR</button>
                <label v-if="data.item.status.status ==='ACTIVO'">ACTIVO</label>
                <label v-if="data.item.status.status ==='FINALIZADO'">FINALIZADO</label>
            </template>
            <template #cell(status.status)="data">
            <!-- <template v-slot:[`item.status.status`]="{ data }"> -->
                <label class="center-column">{{data.item.status.status}}</label>
            </template>
            </b-table>
            <br>
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
            <!-- <input type="submit" value="Nuevo" class="btn btn-success float-left ml-5 " v-on:click="createInvestment" > -->
        </div>

        <div v-if="visualizar2===1 && this.oportunities.length!=0" class="overflow-auto">

            <b-table
                light
                id="my-table"
                :items="formartedItems2"
                :fields="fieldsoportunities"
                :per-page="perPage"
                :current-page="currentPage"
                thead-class="dark-bg bg-dark text-white text-center"
                @row-clicked="myRowClickHandler2"
                small
            >
            <template #cell(initial_amount)="data">
                <label class="float-right">{{stringtoMoney(data.item.initial_amount)}}</label>
            </template>
            <template #cell(initial_amount)="data">
                <label class="float-right">{{stringtoMoney(data.item.initial_amount)}}</label>
            </template>
            <template #cell(rate.text)="data">
                <label class="float-right">{{data.item.rate.text}}</label>
            </template>
            <template #cell(term.text)="data">
                <label class="center-column">{{data.item.term.text}}</label>
            </template>
            <template #cell(start_date)="data">
                <label class="float-right">{{new Date(data.item.start_date).getDate() + '/' + (new Date(data.item.start_date).getMonth()+1) + '/' + new Date(data.item.start_date).getFullYear() }}</label>
            </template>
            </b-table>
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows2"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
            <!-- <input type="submit" value="Nuevo" class="btn btn-success float-left ml-5 " v-on:click="createInvestment" > -->
        </div>


    </b-card>
</template>
<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import EventBus from '@/bus'


export default {
    name: 'Oportunities',
    data () {
        return {
            visualizar: 0,visualizar2:0,
            texto: '',
            perPage: 5,
            currentPage: 1,
            investments: [],
            oportunities: [],
            investment: 0,
            fields:[
                {
                    key: 'namecomplete', sortable: true, label: 'Cliente'
                },
                {
                    key: 'initial_amount', sortable: true, label: 'Monto', thClass: 'monto-column'
                },
                {
                    key: 'rate.text', sortable: true, label: 'Tasa', thClass: 'tasa-column'
                },
                {
                    key: 'term.text', sortable: true, label: 'Plazo', thClass: 'plazo-column'
                },
                {
                    key: 'start_date', sortable: true, label: 'Fecha de Inicio', thClass: 'start-date-column'
                },
                {
                    key: 'status.status', sortable: true, label: 'Estatus', name : 'status', thClass: 'status-column'
                }
            ],
            fieldsoportunities:[
                {
                    key: 'id_oportunity', sortable: true, label: '#Oportunidad'
                },
                {
                    key: 'initial_amount', sortable: true, label: 'Monto', thClass: 'monto-column'
                },
                {
                    key: 'amount_returned', sortable: true, label: 'Monto FD^v'
                },
                {
                    key: 'rate.text', sortable: true, label: 'Tasa', thClass: 'tasa-column'
                },
                {
                    key: 'term.text', sortable: true, label: 'Plazo', thClass: 'plazo-column'
                },
                {
                    key: 'start_date', sortable: true, label: 'Fecha Inicio', thClass: 'start-date-column'
                }
            ],
            mensaje: {color: 'success', texto: ''}
        }
    },
    methods: {
        ...mapActions(['getInvestments','getInvestment','getOportunities','getOportunity', 'buscador', 'cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return '$' + x1 + x2
        },
        createInvestment() {
            this.visualizar = 1
            this.buscador('')
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        myRowClickHandler(record, index) {
            this.visualizar2 = 1
            this.buscador('')
            this.getOportunity(record)
            console.log(record);

            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catoportunities/'+record.id_investment, config)
            .then(res => {
                this.oportunities = res.data[0].oportunities
                // console.log(this.oportunities)
                this.getOportunities(this.oportunities)
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })

            EventBus.$emit('add-ruler', this.visualizar)
        },
        myRowClickHandler2(record, index) {
            this.visualizar = 2
            this.buscador('')
            this.getOportunity(record)
            console.log(record);
            EventBus.$emit('add-ruler', this.visualizar)
        },
        listInvestments(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/oportunities', config)
            .then(res => {
                this.investments = res.data
                // console.log(this.investments);
                this.getInvestments(this.investments)
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })
        },
        removeCustomers(item){
            let a = confirm("Realmente desea eliminar la inversion del cliente " + item.customername)
                if(a === true){
                let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
                console.log(item);
                this.axios.delete('/investments/' + item.id_investment , config)
                .then(res => {
                    this.eliminarInvestment(item)
                    this.mensaje.color = 'danger'
                    this.mensaje.texto = 'Se ha eliminado la Inversion'
                    this.alerta(this.mensaje)
                })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
                })
            }
        },
        activarCustomers(item){
            let a = confirm("Realmente desea activar la inversion del cliente " + item.namecomplete)
            if(a === true){
                item.status = {
                    "id_status": 1,
                    "status": "ACTIVO"
                }
                let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
                this.axios.put('/investments' , item , config)
                .then(res => {
                    this.activarInvestment(item)
                    this.mensaje.color = 'success'
                    this.mensaje.texto = 'Se ha activado la Inversion'
                    this.alerta(this.mensaje)
                })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
                })
            }
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        getVariant (status) {
            switch (status) {
            case "ACTIVO":
                return 'success'
            case "FINALIZADO":
                return 'active'
             default:
                return 'warning'
            }
        }
    },
    computed: {
        ...mapState(['token']),
        ...mapGetters(['filteredInvestment']),
        rows() {
            return this.filteredInvestment.length
        },
        rows2() {
            return this.filteredInvestment.length
        },
        formartedItems () {
            if (!this.filteredInvestment) return []
            return this.filteredInvestment.map(item => {
            // item._rowVariant  = this.getVariant(item.status.status)
            return item
            })
        },
        formartedItems2 () {
            if (!this.oportunities) return []
            return this.oportunities.map(item => {
            // item._rowVariant  = this.getVariant(item.status.status)
            return item
            })
        }
    },
    created(){
        this.listInvestments()
    }
}
</script>
