<template>
    <b-card no-body class=" table-shadow table-container">
        <!-- <b-card-header class="border-0" @submit.prevent="buscador(texto)">
            <h1 class="mb-0">Clientes</h1>
            <input type="submit" value="Nuevo" class="btn btn-success float-right ml-5 " v-on:click="createCustomer" >
            <input type="text" placeholder="Buscar..." v-model="texto" v-on:keyup="buscador(texto)" class="float-right mt-2">
        </b-card-header> -->
        
        <div class="overflow-auto table-secondary">
            <b-table
                hover
                striped
                id="my-table"
                :items="formartedItems"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                thead-class="table-header-bg text-white  text-center"
                @row-clicked="myRowClickHandler"
                small
            >
            </b-table>
            <br>
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            ></b-pagination>
        </div>

    </b-card>
</template>
<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import EventBus from '@/bus'


export default {
    name: 'Customers',
    data () {
        return {
            visualizar: 0,
            texto: '',
            perPage: 50,
            currentPage: 1,
            customers: [],
            customer: 0,
            fields:[
                {
                    key: 'customertypes.type_description', sortable: true, label: 'Tipo'
                },
                {
                    key: 'business_name', sortable: true, label: 'Razon Social'
                },
                {
                    key: 'rfc', sortable: true, label: 'RFC'
                },
                {
                    key: 'name', sortable: true, label: 'Nombre'
                },
                {
                    key: 'last_name', sortable: true, label: 'Apellido Paterno'
                },
                {
                    key: 'm_last_name', sortable: true, label: 'Apellido Materno'
                }
            ],
            mensaje: {color: 'success', texto: ''}
        }
    },
    methods: {
        ...mapActions(['getCustomers','getCustomer', 'buscador', 'cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1 + x2 + ' $'
        },
        createCustomer() {
            this.visualizar = 1
            this.buscador('')
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        myRowClickHandler(record, index) {
            this.visualizar = 2
            this.buscador('')
            this.getCustomer(record)
            EventBus.$emit('add-ruler', this.visualizar)
        },
        listCustomers(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/customers', config)
            .then(res => {
                this.customers = res.data
                // console.log(this.customers);
                this.getCustomers(this.customers)
            })
            .catch(e => {
                if(e.response.status === 403){
                    this.cerrarSesion()
                }
            })
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        getVariant (tipo) {
            switch (tipo) {
            case "PF":
                return 'success'
            case "PM":
                return 'active'
            }
        }
    },
    computed: {
        ...mapState(['token']),
        ...mapGetters(['filteredCustomer']),
        rows() {
            return this.filteredCustomer.length
        },
        formartedItems () {
            if (!this.filteredCustomer) return []
            return this.filteredCustomer.map(item => {
            item._rowVariant  = this.getVariant(item.tipo)
            return item
            })
        }
    },
    created(){
        this.listCustomers()
    }
}
</script>
