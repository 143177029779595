<template>
    <!-- <b-card no-body>
            <b-card-body class="cardeditinvestment mb-5">
                <b-form @submit.prevent="editInvestment()">
                    <div class="row mt-2">
                        <div class="col">
                            <label>Clientes: </label>
                        </div>
                        <div class="col">
                            <label> {{customer}} </label>
                        </div>
                        <div class="col">
                            <label>Fecha Inicio (estimado): </label>
                        </div>
                        <div class="col">
                            <input type="date" v-model="mydate">
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <label>Monto: </label>
                                </div>
                                <div class="col">
                                    <input type="number" step="0.01" class="form-control" placeholder="Monto*" v-model="monto">
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <label>Tasa:</label>
                                </div>
                                <div class="col">
                                    <b-form-select v-model="tasavalor" :options="tasa"></b-form-select>
                                </div>
                                <div class="col">
                                    <label>Plazo:</label>
                                </div>
                                <div class="col">
                                    <b-form-select v-model="plazovalor" :options="plazo"></b-form-select>
                                </div>
                                <div class="col">
                                    <label>Plazo de pago: </label>
                                </div>
                                <div class="col">
                                    <b-form-select v-model="plazopagovalor" :options="plazopago"></b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label class="float-left"> Reinversion</label>
                            <input type="checkbox" class="float-left ml-3 mt-1" v-model="reinversion">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-warning mt-5">Editar</button>
                        </div>
                    </div>
                </b-form>
            </b-card-body>

            <b-card-body class="cardinvestment mb-4">
                <b-form>
                    <div class="row mt-2">
                        <div class="col">
                            <label>Ganancia Proyectada Diaria: {{stringtoMoney(gananciaDiaria().toFixed(2))}}</label>
                        </div>
                        <div class="col">
                            <label>ISR: {{stringtoMoney(isr().toFixed(2))}}</label>
                        </div>
                        <div class="col">
                            <label>Fecha Termino: {{fechaFin()}}</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <label>Ganancia Bruta: {{stringtoMoney(gananciaTotal().toFixed(2))}}</label>
                        </div>
                        <div class="col">
                            <label>Ganancia Neta: $ {{stringtoMoney((gananciaTotal()-isr()).toFixed(2))}}</label>
                        </div>
                        <div class="col">
                            <label>IVA: 0</label>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <label></label>
                        </div>
                        <div class="col">
                            <label>Gran Total: {{stringtoMoney(total().toFixed(2))}}</label>
                        </div>
                    </div>
                </b-form>
            </b-card-body>
    </b-card> -->


    <b-card no-body>
        <div class="row my-5 mx-3">
            <div class="col-md-4 order-md-2">
                <h4 class="d-flex justify-content-between align-items-center">
                    <span class="text-muted">Detalles Inversion</span>
                </h4>
                <ul class="list-group mb-3 sticky-top">
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia proyectada diaria</h4>
                            <small class="text-muted">Ganancia bruta al día</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaDiaria().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">ISR</h4>
                            <small class="text-muted">Reducciones ISR</small>
                        </div>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(20).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(isr(0).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Fecha termino</h4>
                        </div>
                        <span class="text-muted">{{fechaFinMuestra()}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia bruta</h4>
                            <small class="text-muted">Ganancia sin impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(gananciaTotal().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">Ganancia neta</h4>
                            <small class="text-muted">Ganancia con impuestos</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney((gananciaTotal()-this.isr(this.valisr)).toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA causado</h4>
                            <small class="text-muted">IVA generado</small>
                        </div>
                        <span class="text-muted" v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                            <h4 class="my-0">IVA retenido</h4>
                            <small class="text-muted">IVA retenido para pagar</small>
                        </div>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description==='PF' & this.plazovalor.length !=0 & this.tasavalor.length !=0">-{{stringtoMoney(iva().toFixed(2))}}</span>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description==='PM' & this.plazovalor.length !=0 & this.tasavalor.length !=0">$0.00</span>
                        <span class="text-muted" v-if="this.investment.customer.customertypes.type_description===undefined || this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</span> 
                    </li>
                    <li class="list-group-item d-flex justify-content-between">
                        <span>Total (MEX)</span>
                        <strong v-if="this.plazovalor.length !=0 & this.tasavalor.length !=0">{{stringtoMoney(total().toFixed(2))}}</strong>
                        <strong v-if="this.plazovalor.length ===0 || this.tasavalor.length ===0">$0.00</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-8 order-md-1">
                <form @submit.prevent="editInvestment()">
                    <div class="mb-3">
                        <label>Capital inicial</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" class="form-control" min="50000" step="0.01" v-model="investment.initial_amount" required="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mb-3">
                            <label class="mr-2">Cliente : </label>
                            <br>
                            <label> {{this.investment.namecomplete}}</label>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Fecha Inicio (estimado) </label>
                            <br>
                            <input type="date" v-model="fechainicio" style="width: 100%; height:50%;">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label>Tasa:</label>
                            <b-form-select v-model="tasavalor" :options="tasa"></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo:</label>
                            <b-form-select v-model="plazovalor" :options="plazo"></b-form-select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label>Plazo de pago:</label>
                            <b-form-select v-model="plazopagovalor" :options="plazopago"></b-form-select>
                        </div>
                    </div>
                    <hr class="mb-4">
                    <button class="btn btn-warning btn-lg btn-block">Editar Inversion</button>
                </form>
            </div>
        </div>
    </b-card>
</template>

<script>
import EventBus from '@/bus'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'EditInvestment',
    data () {
        return {
            customers:[],perro: "lala",
            visualizar: 0,
            fechainicio : null,
            plazo: [],
            tasa: [],
            plazopago: [
                // { value: 0, text: 'Selecciona una opcion'},
                // { value: 30, text: '30 dias' },
                // { value: 40, text: '40 dias' },
                // { value: 90, text: '90 dias' }
            ],
            plazovalor: [],
            tasavalor: [],
            plazopagovalor: [],
            customer: [],
            monto: 100000,
            reinversion: true,
            mensaje: {color: 'success', texto: ''},
            valisr:0
        }
    },
    filters: {
        capitalize: function (value) {
            return value.toUpperCase()
        }
    },
    created(){
        this.listPlazos(),
        this.listTasa(),
        this.listPlazoPago()
        this.fechainicio = this.extraerfecha(this.investment.start_date)
        this.tasavalor = this.investment.rate
        this.plazovalor = this.investment.term
        this.plazopagovalor = this.investment.paymentTerms
        // console.log(this.investment);
    },
    computed:{
        ...mapState([ 'token' , 'investment' ])
    },
    methods: {
        ...mapActions(['cerrarSesion']),
        stringtoMoney(item){
            item += ''
            var x = item.split('.')
            var x1 = x[0]
            var x2 = x.length > 1 ? '.' + x[1] : ''
            var rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return '$' + x1 + x2
        },
        listPlazos(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catterms/TI', config)
            .then(res => {
                // this.plazo=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazo=json
            })
            .catch(e => {
            })
        },
        listPlazoPago(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catpaymentsterms/PI', config)
            .then(res => {
                // this.plazopago=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.plazopago=json
            })
            .catch(e => {
            })
        },
        listTasa(){
            let config = {
                headers: {
                    Accept: "application/json",
                    Authorization: this.token
                }
            }
            this.axios.get('/catrate/TI', config)
            .then(res => {
                // this.tasa=res.data
                let json = []
                for (let clave in res.data){
                    json.push({text:res.data[clave].text,value: res.data[clave]})
                }
                this.tasa=json
            })
            .catch(e => {
            })
        },
        extraerfecha(date){
            let fecha = new Date(date)
            let a = fecha.getFullYear()
            let d = '' + fecha.getDate()
            let m = '' + (fecha.getMonth() + 1)
            if( m.length < 2 ){
                m = '0' + m
            }
            if( d.length < 2 ){
                d = '0' + d
            }
            return a + '-' + m + '-' + d
        },
        editInvestment(){
            // console.log(this.investment.customer.customertypes.type_description);
            // console.log(this.fechaFin() + 'T00:00:00');
            // console.log(this.fechainicio + 'T00:00:00');
            let config = {
                    headers: {
                        Accept: "application/json",
                        Authorization: this.token
                    }
                }
            this.investment.start_date = Date.parse(this.fechainicio + 'T00:00:00')
            this.investment.end_date = Date.parse(this.fechaFin() + 'T00:00:00')
            this.investment.paymentTerms = this.plazopagovalor
            this.investment.rate = this.tasavalor
            this.investment.term = this.plazovalor
            this.investment.balance = this.investment.initial_amount
            this.investment.total = this.total()
            
            this.axios.put('/investments', this.investment, config)
                .then(res => {
                    this.mensaje.color = 'success'
                    this.mensaje.texto = 'Se ha agregado la Inversion'
                    this.alerta(this.mensaje)
                    this.getInvestments()
            })
                .catch(e => {
                    if(e.response.status === 403){
                        this.cerrarSesion()
                    }
            })
        },
        getInvestments() {
            this.visualizar = 0
            EventBus.$emit('add-ruler', this.visualizar)            
        },
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        fechaFin() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return a + '-' + m + '-' + d
            }
        },
        fechaFinMuestra() {
            if(this.fechainicio != null){
                let fechafin = new Date(this.fechainicio + 'T00:00:00')
                fechafin.setDate(fechafin.getDate() + this.plazovalor.value)
                let a = fechafin.getFullYear()
                let d = '' + fechafin.getDate()
                let m = '' + (fechafin.getMonth() + 1)
                if( m.length < 2 ){
                    m = '0' + m
                }
                if( d.length < 2 ){
                    d = '0' + d
                }
                return d + '/' + m + '/' + a
            }
        },
        gananciaDiaria(){
            switch (this.plazovalor.value) {
                case 0:
                    return 0
                    break;
                default:
                    return this.gananciaTotal()/this.plazovalor.value
                    break;
            }
        },
        gananciaTotal(){
            return (this.plazovalor.value/360)*this.tasavalor.value*this.investment.initial_amount/100
        },
        isr(val){
            return (parseInt(val)*this.gananciaTotal())/100
        },
        iva(){
            return (this.gananciaTotal()*16)/100
        },
        total(){
            switch (this.investment.customer.customertypes.type_description) {
                case "PF":
                    // console.log("PF");
                    this.valisr = 20;
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.investment.initial_amount) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
                case "PM":
                    // console.log("PM");
                    this.valisr = 0;
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.investment.initial_amount) + this.gananciaTotal() - this.isr(this.valisr) +this.iva()
                    break;
                default:
                    // console.log("DEFAULT");
                    this.valisr = 0
                    // console.log(this.isr(this.valisr));
                    return parseFloat(this.investment.initial_amount) + this.gananciaTotal() - this.isr(this.valisr)
                    break;
            }
            
        }
    }
}
</script>