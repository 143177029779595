<template>
  <b-card no-body class="card-profile" alt="Image placeholder" img-top>
    <b-row class="justify-content-center">
      <b-col lg="3" class="order-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <b-img src="img/theme/descarga.png" rounded="circle" />
          </a>
        </div>
      </b-col>
    </b-row>

    <b-card-header class="text-center profile-header border-0 pt-8 pt-md-4 pb-0 pb-md-4">
    </b-card-header>

    <b-card-body class="pt-0">
      <b-row>
        <b-col >
          <div class="card-profile-stats d-flex justify-content-center mt-md-6 card-text">
            <div>
              <span class="heading">22</span>
              <span class="description">Inversiones</span>
            </div>
            <div>
              <span class="heading">10</span>
              <span class="description">Oportunidades</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="text-center card-text">
        <h5 class="h3 card-text">
          Jessica Jones
        </h5>
        <div class="h5 font-weight-300 card-text">
          <i class="ni location_pin mr-2"></i>jessica@jkrasesores.com
        </div>
        <!--
        <div class="h5 mt-4">
          <i class="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer
        </div>
        <div>
          <i class="ni education_hat mr-2"></i>University of Computer Science
        </div>
        -->
        <hr class="my-4">
        <p>Coordinador de Desarrollo Web, Ingeniero en Sistemas e integrante del equipo de T.I. en JRK Asesores</p>

      </div>
      
    </b-card-body>
        <img class="profile-hr" src="../../../assets/img/profile-hr.png">
  </b-card>
</template>
<script>
export default {};
</script>
<style scoped>
@import '../../../assets/styles/style.css';
</style>
