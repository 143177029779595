<template>
  <div>
    <!-- Header -->
    <div class="header bg-dashboardtop py-7 py-lg-8 pt-lg-9">
      <!-- <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div> -->
    </div>
    
    <!-- Page content -->
    <b-container class="mt--9">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h1>Iniciar Sesion</h1>
              </div>
              <validation-observer ref="formValidator">
                <b-form role="form" @submit.prevent="login()">
                  <base-input alternative
                              class="mb-3"
                              name="Email"
                              :rules="{required: true, email: true}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Correo"
                              v-model="usuario.user">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Contraseña"
                              v-model="usuario.password">
                  </base-input>

                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Ingresar</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6">
              <router-link to="/dashboard" class="text-light"><small>¿Olvidaste tu Contraseña?</small></router-link>
            </b-col>
            <b-col cols="6" class="text-right">
              <router-link to="/register" class="text-light"><small>Nuevo Usuario</small></router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div class="bg-dashboardbottom my--9"></div> -->
    <div id="footer"></div>
    
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import EventBus from '@/bus'
  export default {
    data() {
      return {
        usuario: { user: '', password: '' },
        mensaje: {color: 'success', texto: ''}
      };
    },
    methods: {
        ...mapActions(['guardarUsuario']),
        alerta(item) {
            EventBus.$emit('alerta', item)            
        },
        login(){
            // console.log(this.usuario)
            this.axios.post('/login', this.usuario)
            .then(res => {
                // console.log(res.data)
                const token = res.data.token
                this.guardarUsuario(token)
            })
            .catch(e =>{
                this.mensaje.color = 'danger'
                this.mensaje.texto = 'Usuario y/o Contraseña equivocados'
                this.alerta(this.mensaje)
                console.log(e);
            })
        }
    }
  };
</script>

<style scoped>
.button {
  border: none;
  border-radius: 2px;
}

.bg-dashboardtop {
  background-color: #F2F0DF;
  /* background: url("../../assets/login/fondoinferiorlogin.png"); */
}

.bg-dashboardbottom {
  position: fixed;
    bottom: 0;
    width: 100%;
  background: url("../../assets/login/fondoinferiorlogin.png");
  /* height: 725px; */
  /* padding: 0 0 750px 0; */
  background-repeat: no-repeat;
  
  
}
#footer {
    background: url("../../assets/login/fondoinferiorlogin.png");
    line-height: 2;
    height: 750px;
    margin-top: -15.35%;
}
</style>